<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#" v-if="vmUserLoyaltyFormData && Object.keys(vmUserLoyaltyFormData).length > 0">
              <div class="form-row">

                <div class="col-md-12 mb-3">
                  <label for="validationmodule_name">
                    {{ cvModuleNameLabel }}</label>
                  <input v-model="vmUserLoyaltyFormData.module_name" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_obj_id">
                    {{ cvModuleObjIdLabel }}</label>
                  <input v-model="vmUserLoyaltyFormData.module_obj_id" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_action">
                    {{ cvModuleActionLabel }}</label>
                  <input v-model="vmUserLoyaltyFormData.module_action" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_points">
                    {{ cvModulePointsLabel }}</label>
                  <input v-model="vmUserLoyaltyFormData.module_points" type="text" class="form-control" required />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="user_loyaltyEdit()">{{ cvSubmitBtn }}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { UserLoyaltys } from "../../../FackApi/api/UserLoyalty.js"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "UserLoyaltyEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propUserLoyaltyObj: {
      type: Object,
      default: function () {
        return {
          "module_name": "",
          "module_obj_id": "",
          "module_action": "",
          "module_points": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit User Loyalty",
      cvCardSubHeader: "Edit User Loyalty ",
      cvSubmitBtn: "Edit",
      cvModuleNameLabel: "module name",
      cvModuleObjIdLabel: "module obj id",
      cvModuleActionLabel: "module action",
      cvModulePointsLabel: "module points",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "UserLoyalty Edit Response",
      vmUserLoyaltyFormData: {}
    }
  },
  mounted () {
    this.user_loyaltyView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmUserLoyaltyFormData) {
          if (!this.vmUserLoyaltyFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * user_loyaltyView
     */
    async user_loyaltyView () {
      try {
        if (this.propOpenedInModal) {
          this.vmUserLoyaltyFormData = this.propUserLoyaltyObj
        }
        else {
          let userLoyaltyId = this.$route.params.loy_id
          let userLoyaltyViewResp = await UserLoyaltys.user_loyaltyView(this, userLoyaltyId)

          if (userLoyaltyViewResp && userLoyaltyViewResp.resp_status) {
            this.vmUserLoyaltyFormData = userLoyaltyViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at user_loyaltyView() and Exception:", err.message)
      }
    },
    /**
     * user_loyaltyEdit
     */
    async user_loyaltyEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let userLoyaltyAddResp = await UserLoyaltys.user_loyaltyEdit(this, this.vmUserLoyaltyFormData)
        await ApiResponse.responseMessageDisplay(this, userLoyaltyAddResp)

        if (userLoyaltyAddResp && !userLoyaltyAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseUserLoyaltyEditModal", this.vmUserLoyaltyFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at user_loyaltyEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
