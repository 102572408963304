<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="user_loyaltyAdd">{{ cvbtnAddNew }}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col md="4">
                <b-form-input v-model="filter" type="search" :placeholder="cvSearchText"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="user_loyaltyObjList && user_loyaltyObjList.length > 0">
                <b-table hover responsive :items="user_loyaltyObjList" :fields="columns" :no-border-collapse="true"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                  :current-page="currentPage" :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{ getFormattedDateTime(data.item.created_on) }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="user_loyaltyEdit('MODAL', data.item)"
                      v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showUserLoyaltyDeleteDialog(data.item)" size="sm"><i
                        class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="showModelUserLoyaltyAdd" scrollable :title="cvAddModalHeader" size="xl">
      <UserLoyaltyAdd :propOpenedInModal="true" @emitCloseUserLoyaltyAddModal="closeUserLoyaltyAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="user_loyaltyAdd('FULLPAGE')">
          {{ cvbtnModalExpand }}
        </b-button>
        <b-button size="sm" @click="closeUserLoyaltyAddModal()">
          {{ cvbtnModalCancel }}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelUserLoyaltyEdit" scrollable :title="cvEditModalHeader" size="xl
    ">
      <UserLoyaltyEdit :propOpenedInModal="true" :propUserLoyaltyObj="user_loyaltyEditObj"
        @emitCloseUserLoyaltyEditModal="closeUserLoyaltyEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="user_loyaltyEdit('FULLPAGE')">
          {{ cvbtnModalExpand }}
        </b-button>
        <b-button size="sm" @click="closeUserLoyaltyEditModal()">
          {{ cvbtnModalCancel }}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelUserLoyaltyDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{ cvDelMessage }}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelUserLoyaltyDelete = false">
          {{ cvbtnModalCancel }}
        </b-button>
        <b-button size="sm" variant="primary" @click="user_loyaltyDelete()">
          {{ cvbtnModalDelOk }}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { UserLoyaltys } from "../../../FackApi/api/UserLoyalty.js"
import UserLoyaltyAdd from "./UserLoyaltyAdd"
import UserLoyaltyEdit from "./UserLoyaltyEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "UserLoyaltyList",
  components: {
    UserLoyaltyAdd,
    UserLoyaltyEdit
  },
  data () {
    return {
      cvCardTitle: "User Loyalty",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit UserLoyalty",
      cvAddModalHeader: "Add UserLoyalty",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "UserLoyalty List Response",
      showModelUserLoyaltyAdd: false,
      showModelUserLoyaltyEdit: false,
      showModelUserLoyaltyDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "Name", key: "module_name", class: "text-left align-text-top", sortable: true },
        { label: "Obj Id", key: "module_obj_id", class: "text-left align-text-top", sortable: true },
        { label: "Action", key: "module_action", class: "text-left align-text-top", sortable: true },
        { label: "Points", key: "module_points", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      user_loyaltyObjList: null,
      user_loyaltyEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null
    }
  },
  mounted () {
    this.user_loyaltyList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * user_loyaltyList
     */
    async user_loyaltyList () {
      try {
        let userLoyaltyListResp = await UserLoyaltys.user_loyaltyList(this)
        if (userLoyaltyListResp.resp_status) {
          this.user_loyaltyObjList = userLoyaltyListResp.resp_data.data
          this.totalRows = userLoyaltyListResp.resp_data.count
        }
        else {
          this.toastMsg = userLoyaltyListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at user_loyaltyList() and Exception:", err.message)
      }
    },
    /**
     * user_loyaltyAdd
     */
    user_loyaltyAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/user_loyalty_add")
        }
        else {
          this.showModelUserLoyaltyAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at user_loyaltyAdd() and Exception:", err.message)
      }
    },
    /**
     * user_loyaltyEdit
     */
    user_loyaltyEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/user_loyalty_edit/" + this.user_loyaltyEditObj.loy_id)
        }
        else {
          this.user_loyaltyEditObj = item
          this.showModelUserLoyaltyEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at user_loyaltyEdit() and Exception:", err.message)
      }
    },
    /**
     * showUserLoyaltyDeleteDialog
     */
    showUserLoyaltyDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelUserLoyaltyDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showUserLoyaltyDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * user_loyaltyDelete
     */
    async user_loyaltyDelete () {
      try {
        let userLoyaltyDelResp = await UserLoyaltys.user_loyaltyDelete(this, this.delObj.loy_id)
        await ApiResponse.responseMessageDisplay(this, userLoyaltyDelResp)

        if (userLoyaltyDelResp && !userLoyaltyDelResp) {
          this.showModelUserLoyaltyDelete = false
          return false
        }

        let index = this.user_loyaltyObjList.indexOf(this.delObj)
        this.user_loyaltyObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelUserLoyaltyDelete = false
      }
      catch (err) {
        console.error("Exception occurred at user_loyaltyDelete() and Exception:", err.message)
      }
    },
    /**
     * closeUserLoyaltyAddModal
     */
    closeUserLoyaltyAddModal (userLoyaltyAddData) {
      try {
        if (userLoyaltyAddData) {
          if (this.user_loyaltyObjList && this.user_loyaltyObjList.length >= 1) {
            let loyObjLength = this.user_loyaltyObjList.length
            let lastId = this.user_loyaltyObjList[loyObjLength - 1]["id"]
            userLoyaltyAddData.id = lastId + 1
          }
          else {
            this.user_loyaltyObjList = []
            userLoyaltyAddData.id = 11111
          }

          userLoyaltyAddData.created_on = moment()
          this.user_loyaltyObjList.unshift(userLoyaltyAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelUserLoyaltyAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeuser_loyaltyAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeUserLoyaltyEditModal
     */
    closeUserLoyaltyEditModal () {
      try {
        this.showModelUserLoyaltyEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeUserLoyaltyEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
